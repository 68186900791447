
.image_hover {
  margin: 0 auto;
  maxWidth: 570px;
  height: auto;
  width: 100%;
  borderRadius: 3px;
}

.image_hover:hover {
  margin: 0 auto;
  max-width: 570px;
  height: auto;
  width: 100%;
    opacity:1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.gif {
  height: auto;
  max-width: 100%;
  background-image: url('../../assets/loops.gif');
}

.section_indent {
  padding-left:20px;
  opacity: .9;
}

.aws-btn {

  --slider-height-percentage: 60%;
  --slider-transition-duration: 316ms;
  --organic-arrow-thickness: 2px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 27px;
  --organic-arrow-color: #cc7c80;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #f09297;
  --control-bullet-active-color: #cc7c80;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
}
.awssld {
  --organic-arrow-color: red !important;
  --content-background-color: #240000 !important;
}

.awssld__content > img {
  object-fit: scale-down !important;
}

.awssld__controls__arrow {
  color:red;
}